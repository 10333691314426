/**
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
 | 沪上工业通 - 上海找厂房 就上工业通
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 版权所有 © 2024-2025 上海聚未投资管理有限公司
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 本平台及其相关内容受《中华人民共和国著作权法》及相关法律法规保护。
 | 未经上海聚未投资管理有限公司书面授权，任何单位或个人不得以任何形式
 | 复制、修改、传播或用于商业用途。
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 官方网站：www.gongyetong.cn
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
*/

// src/services/utils.js

import utilsRequest from "@/utils/utilsRequest";

/**
 * 发送错误邮件
 * @param {string} subject - 错误主题
 * @param {string} file - 发生错误的文件
 * @param {string} functionName - 发生错误的函数
 * @param {Error|Object} error - 错误对象
 * @param {Object} [extra] - 额外信息（可选）
 */
export function sendErrorEmail(data = {}) {
    // 参数基础验证
    if (!data.subject || !data.file || !data.functionName) {
        throw new Error('必须提供 subject、file 和 functionName');
    }

    // 提取错误信息
    const errorInfo = {
        message: data.error?.message ?? '未知错误',
        name: data.error?.name ?? 'Unknown Error',
        stack: data.error?.stack ?? '',
        code: data.error?.code ?? '',
        // 处理 Axios 错误特有的配置
        baseURL: data.error?.config?.baseURL ?? '',
        method: data.error?.config?.method ?? '',
        url: data.error?.config?.url ?? ''
    };

    // 格式化时间
    const timestamp = new Date().toLocaleString('zh-CN', { 
        timeZone: 'Asia/Shanghai' 
    });

    // 构建邮件内容
    const sendData = {
        to: "fengyi.cai@foxmail.com",
        subject: `[WEB] Error Log Notification: ${data.subject}`,
        body: `
⏰ 时间: ${timestamp}
🔰 级别: ERROR
📍 位置: ${data.file} - ${data.functionName}
💬 消息: ${data.subject}

📋 额外信息:
${JSON.stringify(data.extra, null, 2)}

🚨 错误详情:
* 错误类型: ${errorInfo.name}
* 错误信息: ${errorInfo.message}
${errorInfo.code ? `* 错误代码: ${errorInfo.code}` : ''}
${errorInfo.baseURL ? `* 请求地址: ${errorInfo.baseURL}` : ''}
${errorInfo.url ? `* 请求路径: ${errorInfo.url}` : ''}
${errorInfo.method ? `* 请求方法: ${errorInfo.method}` : ''}
${errorInfo.stack ? `* 堆栈信息:\n${errorInfo.stack}` : ''}
`.trim()
    };

    // 发送邮件
    return utilsRequest.post('/send-email', sendData)
        .catch(error => {
            console.error('发送错误邮件失败:', error);
            throw error;
        });
}

// 使用示例：
/*
proxy.$sendErrorEmail{
    subject: "更新个人信息失败",
    file: CONFIG.FILE_NAME,
    functionName: "submitForm",
    error: error,
    extra: {}
};
*/