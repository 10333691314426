const housingRoutes = [
    {
        path: 'properties',
        name: 'AdminPropertyList',
        component: () => import('@/modules/admin/views/housing/PropertyList/index.vue'),
    },
    {
        path: 'properties/edit/:id',
        name: 'EditProperty',
        component: () => import('@/modules/admin/views/housing/PropertyForm/index.vue'),
    },
    {
        path: 'properties/add',
        name: 'AddProperty',
        component: () => import('@/modules/admin/views/housing/PropertyForm/index.vue'),
    },
    {
        path: 'videos',
        name: 'AdminVideoList',
        component: () => import('@/modules/admin/views/housing/VideoList/index.vue'),
    },
    {
        path: 'videos/edit/:id',
        name: 'EditVideo',
        component: () => import('@/modules/admin/views/housing/VideoForm/index.vue'),
    },
    {
        path: 'videos/add',
        name: 'AddVideo',
        component: () => import('@/modules/admin/views/housing/VideoForm/index.vue'),
    },
]

const cmsRoutes = [
    {
        path: 'user-profile',
        component: () => import('@/modules/admin/views/cms/UserProfile/index.vue'),
        name: 'UserProfile'
    },
    {
        path: 'change-password',
        component: () => import('@/modules/admin/views/cms/ChangePassword/index.vue'),
        name: 'ChangePassword'
    },
]

// 管理后台路由配置
const adminRoutes = [
    {
        path: '', // 空路径用于匹配 /admin
        redirect: {name: 'Dashboard'} // 重定向到 Dashboard
    },
    {
        path: 'dashboard',
        component: () => import('@/modules/admin/views/DashboardPage/index.vue'),
        name: 'Dashboard'
    },
    {
        path: 'housing',
        children: housingRoutes
    },
    {
        path: 'cms',
        children: cmsRoutes
    },

];

export default adminRoutes;