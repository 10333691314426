/**
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
 | 沪上工业通 - 上海找厂房 就上工业通
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 版权所有 © 2024-2025 上海聚未投资管理有限公司
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 本平台及其相关内容受《中华人民共和国著作权法》及相关法律法规保护。
 | 未经上海聚未投资管理有限公司书面授权，任何单位或个人不得以任何形式
 | 复制、修改、传播或用于商业用途。
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 官方网站：www.gongyetong.cn
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
*/

// src/router.js

import {createRouter, createWebHistory} from 'vue-router';

import frontEndRoutes from '@/modules/frontend/router.js';
import adminRoutes from '@/modules/admin/router.js';




// 整体路由配置
const routes = [
    {
        path: '/',
        component: () => import('@/modules/frontend/layouts/MainLayout.vue'),
        children: frontEndRoutes,
    },
    {
        path: '/admin',
        component: () => import('@/modules/admin/layouts/AdminLayout.vue'),
        children: adminRoutes,
        meta: {requiresAuth: true}, // 添加需要权限的标记
    },
];

// 创建路由器实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

// 权限验证方法
function isAuthenticated() {
    // 从 localStorage 或 cookie 中获取用户信息
    const userData = localStorage.getItem('userData');
    return userData && JSON.parse(userData).token; // 根据实际验证条件调整
}

// 全局导航守卫
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (isAuthenticated()) {
            next(); // 允许访问
        } else {
            next({name: 'Login'}); // 重定向到登录页
        }
    } else {
        next(); // 不需要权限，直接访问
    }
});
