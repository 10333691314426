/**
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
 | 沪上工业通 - 上海找厂房 就上工业通
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 版权所有 © 2024-2025 上海聚未投资管理有限公司
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 本平台及其相关内容受《中华人民共和国著作权法》及相关法律法规保护。
 | 未经上海聚未投资管理有限公司书面授权，任何单位或个人不得以任何形式
 | 复制、修改、传播或用于商业用途。
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 官方网站：www.gongyetong.cn
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
*/

// src/api/adminPublicApi.js

import axios from 'axios';

// TODO: 部署前改
export const BASE_URL = process.env.VUE_APP_UTILS_URL;

console.log(BASE_URL)
const utilsRequest = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default utilsRequest;
